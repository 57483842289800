import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

/* Initialize analytics & load plugins */
export const analytics = Analytics({
  app: "dropshipper-app",
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_ANALYTICS,
      debug: process.env.NODE_ENV === "development" // enable debug mode
    })
  ]
});
