export function showModal(currentModal, data) {
  return dispatch => {
    dispatch({
      type: "SHOW_MODAL",
      payload: { currentModal, data }
    });
  };
}

export function closeModal() {
  return dispatch => {
    dispatch({
      type: "CLOSE_MODAL",
      payload: { currentModal: null }
    });
  };
}

export function setSelectedProduct(selectedProduct) {
  return dispatch => {
    dispatch({ type: "SET_SELECTED_PRODUCT", payload: { selectedProduct } });
  };
}

export function clearSelectedProduct() {
  return dispatch => {
    dispatch({ type: "CLEAR_SELECTED_PRODUCT" });
  };
}
