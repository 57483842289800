import ApiCall from "utils/apiCall";
import { transpilerPlanAPI } from "utils/features";

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case "store.plans.isFetching":
      return { ...state, isFetching: true };

    case "store.plans.fetchSuccess":
      return {
        ...state,
        isFetching: false,
        allPlans: action.response,
        cardData: action.cardData,
        maxDiscountPercentage: action.maxDiscountPercentage,
        trialDaysPlan: action.trialDaysPlan
      };

    case "store.plans.fetchError":
      return { ...state, isFetching: false, allPlans: action.response };

    default:
      return state;
  }
}

// Actions
export function getPlans() {
  return dispatch => {
    dispatch({ type: "store.plans.isFetching" });
    return ApiCall.get("/stores/plans")
      .then(response => {
        const { serialised_plans, card_data } = response.json;
        dispatch({
          type: "store.plans.fetchSuccess",
          // TODO - Improve this transpilerPlanAPI function
          response: transpilerPlanAPI(serialised_plans),
          cardData: card_data,
          maxDiscountPercentage: getHighestDiscount(serialised_plans),
          trialDaysPlan: getTrialDays(serialised_plans)
        });
      })
      .catch(error => {
        dispatch({ type: "store.plans.fetchError", error });
      });
  };
}

// Utils
function getHighestDiscount(plans) {
  return transpilerPlanAPI(plans)
    .map(plan => plan.discount_percentage)
    .sort((a, b) => b - a)[0];
}

function getTrialDays(plans) {
  return plans.filter(plan => {
    return plan.name === "Professional" && plan.annual === false;
  })[0].trial_days;
}
