import ApiCall from "utils/apiCall";

const initialState = {
  data: [],
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "suppliers.aliases.isFetching":
      return { ...state, isFetching: true };

    case "suppliers.aliases.fetchSuccess":
      return {
        ...state,
        isFetching: false,
        data: action.response.json
      };

    case "suppliers.aliases.fetchError":
      return { ...state, isFetching: false, ...action.response };

    default:
      return state;
  }
}

// Actions
export function getSuppliers() {
  return async dispatch => {
    dispatch({ type: "suppliers.aliases.isFetching" });

    try {
      const response = await ApiCall.get("/suppliers/aliases");

      dispatch({
        type: "suppliers.aliases.fetchSuccess",
        response
      });
    } catch (error) {
      dispatch({
        type: "suppliers.aliases.fetchError",
        error
      });
    }
  };
}
