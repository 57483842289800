/**
 * Opens a link with a function
 *
 * Ex: openLink("spocket.co", "_top")
 *
 * @param  { String } url
 * @param  { String } target default "_blank"
 * @returns { null }
 *
 */
export function openLink(url, target = "_blank") {
  const win = window.open(url, target);
  win.focus();
}
