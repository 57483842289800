import { combineReducers } from "redux";
import AuthReducer from "./auth";
import MessageReducer from "./message";
import ShippingReducer from "./shipping";
import UpgradeReducer from "./upgrade";
import SettingsReducer from "./settings";
import UiReducer from "./ui";
import FormsReducer from "./forms";
import EventTrackerReducer from "./tracker";
import { reducer as FormReducer } from "redux-form";

// New Dropshipper App

import salesAnalysis from "utils/module/analytics/salesAnalysis";
import topSellingProducts from "utils/module/analytics/topSellingProducts";
import plans from "utils/module/store/plans";
import planCharges from "utils/module/store/planCharges";
import paymentSubscribe from "utils/module/store/paypal/paymentSubscribe";
import paymentSuccess from "utils/module/store/paypal/paymentSuccess";
import order from "utils/module/store/order";
import signUp from "utils/module/signup";
import setLoginEmail from "utils/module/signup";
import updatePaymentProvider from "utils/module/updatePaymentProvider";
import stripeIntegrationAccount from "utils/module/stripeIntegration/account";
import utm from "utils/module/store/utm";
import listingsCategory from "utils/module/shared/listingsCategory";
import featureFlags from "utils/module/featureFlags";
import personalization from "utils/module/store/personalization";
import pause from "utils/module/store/pause";
import pay from "utils/module/store/orders/pay";
import verifyPassword from "utils/module/password/verify";
import store from "utils/module/store";
import downgradeStep from "utils/module/downgradeStep";
import notifications from "utils/module/feeds";
import todaysSales from "utils/module/todaysSales";
import offers from "utils/module/store/offers";
import review from "utils/module/store/review";
import aliases from "utils/module/suppliers/aliases";

const rootReducer = combineReducers({
  form: FormReducer,
  message: MessageReducer,
  forms: FormsReducer,
  shipping: ShippingReducer,
  upgrade: UpgradeReducer,
  auth: AuthReducer,
  settings: SettingsReducer,
  eventTracker: EventTrackerReducer,
  ui: UiReducer,
  analytics: combineReducers({
    salesAnalysis,
    topSellingProducts
  }),
  signUp: signUp,
  setLoginEmail: setLoginEmail,
  store: combineReducers({
    information: store,
    plans,
    planCharges,
    paypal: combineReducers({ paymentSubscribe, paymentSuccess }),
    personalization,
    pause,
    orders: combineReducers({ pay }),
    offers,
    review
  }),
  updatePaymentProvider: updatePaymentProvider,
  payment: stripeIntegrationAccount,
  utm: utm,
  order,
  shared: combineReducers({ listingsCategory }),
  supplier: combineReducers({
    aliases
  }),
  featureFlags,
  verifyPassword,
  downgradeStep,
  notifications,
  todaysSales
});

export default rootReducer;
