import ApiCall from "utils/apiCall";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "downgradeStep.isFetching":
      return { ...state, isFetching: true };

    case "downgradeStep.fetchSuccess":
      return { ...state, isFetching: false, ...action.response };

    case "downgradeStep.fetchError":
      return { ...state, isFetching: false, ...action.response };

    default:
      return state;
  }
}

// Actions
/**
 * All Steps
 * 0 - has_clicked_to_pause_store
 * 1 - has_paused_store
 * 2 - has_clicked_to_close_store
 * 3 - has_provided_password_confirmation
 * 4 - has_clicked_to_contact_us
 * 5 - has_clicked_close_store
 * 6 - has_clicked_past_offer
 *
 * @param  {string} step
 */
export function trackDowngradeStep(step) {
  return async dispatch => {
    dispatch({ type: "downgradeStep.isFetching" });

    try {
      const response = await ApiCall.put("/downgrade_step", { step });

      dispatch({
        type: "downgradeStep.fetchSuccess",
        response
      });
    } catch (error) {
      dispatch({
        type: "downgradeStep.fetchError",
        error
      });
    }
  };
}
