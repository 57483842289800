import { ApiBaseCall } from "utils/apiCall";
import _get from "lodash/get";

const initialState = { featureFlags: [] };

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "getFeatureFlag.success":
      return { ...state, featureFlags: action.data };
    default:
      return state;
  }
}

// Actions
export function getFeatureFlags() {
  return dispatch => {
    ApiBaseCall.get(`/feature_flags`).then(response => {
      const json = _get(response, "json", []);

      dispatch({
        type: "getFeatureFlag.success",
        data: json
      });
    });
  };
}
