import { QueryClient } from "@tanstack/react-query";

// Create a client
export const NewQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
});
