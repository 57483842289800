import React, { Component, createContext } from "react";

export const UserContext = createContext();

export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importedListings: [], // Save user imported items
      pushedListings: [], // Save user pushed items
      downgradeAttemptId: "", // passed id from previous downgrade event to tie the funnel
      showCornerUpgradeButton: false, // save information if upgrade button is shown
      bulkImportInProcess: false, // save information if bulk import process is happening
      lastSeenProductId: "", // save information about the last product seen before a plan upgrade, for example
      lastSelectedPaymentMethod: "stripe_payment", // save selected payment method information to use on Annual Promotion modal
      emailUsedOnSignup: "", // save email used by the user on signup to show again on login page
      onboardingPreferences: {
        selectedCategories: "",
        selectedProductsKind: "",
        selectedRegion: "",
        selectedColorScheme: "",
        selectedPersonality: ""
      }, // save choices made by the user on onboarding process
      sidebarIsCollapsed: false, // save this here because it resets in every route change
      userClickedToConnectNewStore: false, // save information to decide if we should show onboarding
      currentOnboardingStep: {
        id: "",
        stepNumber: null
      }, // we need it to hide the stores when current onboarding step be the pricing plans
      isAbleToCancelSubscription: false, // save information if user is allowed to access cancel page
      bulkSelectedListingsInProcess: false // save information when a bulk import process is happening for selected Listings
    };
  }

  setStoreInfo = (attribute, value) => {
    this.setState({ [attribute]: value });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          setStoreInfo: this.setStoreInfo
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

// Left this here if we eventually need in the future
export const withUserContext = Component => props => (
  <UserContext.Consumer>{state => <Component {...props} context={state} />}</UserContext.Consumer>
);
