import ApiCall from "utils/apiCall";

// Actions
import { closeModal } from "utils/actions/ui";
import { getStore } from "../store";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.review.isFetching":
      return { ...state, isFetching: true };

    case "store.review.fetchSuccess":
      return {
        ...state,
        isFetching: false
      };

    case "store.review.fetchError":
      return { ...state, isFetching: false };

    default:
      return state;
  }
}

// Actions
export function createReview({ value, feedbackText }) {
  return async dispatch => {
    dispatch({ type: "store.review.isFetching" });

    try {
      const response = await ApiCall.post("/stores/reviews", {
        value,
        feedback_text: feedbackText
      });
      dispatch(closeModal());

      dispatch({
        type: "store.review.fetchSuccess",
        response: response.json
      });
      dispatch(getStore());
    } catch (error) {
      dispatch({
        type: "store.review.fetchError",
        error
      });
    }
  };
}
