import ApiCall from "utils/apiCall";
import CollectionApi from "utils/api/Collection";
import SquareLocationApi from "utils/api/SquareLocation";
import _get from "lodash/get";

import { setAlertMessage } from ".";
import { showModal } from "./ui";
import ReactPixel from "react-facebook-pixel";
import { trackAdsByRedux } from "utils/utm";
import { gaEvent } from "utils/trackEvents";

export const SETTINGS_UPDATED_ALL = "SETTINGS_UPDATED_ALL";
export const SETTINGS_UPDATED_PARTIAL = "SETTINGS_UPDATED_PARTIAL";
export const COLLECTIONS_SET = "COLLECTIONS_SET";
export const SQUARE_LOCATIONS_SET = "SQUARE_LOCATIONS_SET";
export const IS_DOWNGRADING = "IS_DOWNGRADING";
export const DROPSHIPPER_DATA_SUCCESS = "DROPSHIPPER_DATA_SUCCESS";
export const SET_COLLECTION_SEARCH = "SET_COLLECTION_SEARCH";
export const EMPIRE_PRORATION_PRICE_SUCCESS = "EMPIRE_PRORATION_PRICE_SUCCESS";
export const SET_DISABLED_INVOICING = "SET_DISABLED_INVOICING";

export function getSettings() {
  return function(dispatch) {
    return ApiCall.get("/api/settings").then(params => {
      const json = _get(params, "json", {});
      dispatch(allSettingsUpdated(json));
    });
  };
}

export function getCollections(integratedStoreId) {
  return dispatch => {
    CollectionApi.index(integratedStoreId)
      .then(params => {
        const json = _get(params, "json", []);
        dispatch(collectionsSet(json));
      })
      .catch(error => error);
  };
}

export function getSquareLocations(integratedStoreId) {
  return dispatch => {
    SquareLocationApi.index(integratedStoreId)
      .then(({ json }) => {
        const locations = _get(json, "locations", []);
        dispatch(squareLocationsSet(locations));
      })
      .catch(error => error);
  };
}

export function getPaymentSettings(type) {
  return function(dispatch) {
    const queryParam = type === "v2" ? "?account_source=new" : "";
    return ApiCall.get(`/stripe_integrations/payment_methods${queryParam}`).then(params => {
      const json = _get(params, "json", []);
      const status = _get(params, "status", 400);

      if (json.length >= 1) {
        dispatch(partialSettingsUpdated({ stripeCustomerId: true }));
      }

      return { status, json };
    });
  };
}

export function getPaymentSubscriptions(type) {
  return function(dispatch) {
    const queryParam = type === "v2" ? "?account_source=new" : "";
    return ApiCall.get(`/stripe_integrations/subscriptions/payment_methods${queryParam}`).then(params => {
      const json = _get(params, "json", []);
      const status = _get(params, "status", 400);

      if (json.length >= 1) {
        dispatch(partialSettingsUpdated({ stripeCustomerId: true }));
      }

      return { status, json };
    });
  };
}

export function getPricingRuleSettings() {
  return function(dispatch) {
    return ApiCall.get("/stores/pricing_rule").then(params => {
      const json = _get(params, "json", {});
      dispatch(partialSettingsUpdated({ rules: json }));
    });
  };
}

export function updateSettings(values) {
  return function(dispatch) {
    return ApiCall.patch("/api/settings", values)
      .then(params => {
        const message = _get(params, "json.message", "Something went wrong...");
        setAlertMessage(message, "success", true);
        dispatch(partialSettingsUpdated(values));
      })
      .catch(params => {
        const message = _get(params, "json.message", "Something went wrong...");
        setAlertMessage(message, "error");
      });
  };
}

export function updatePaymentSubscriptionSettings(token, type, hasCard) {
  return function(dispatch) {
    const queryParam = type === "v2" ? "?account_source=new" : "";
    return ApiCall.post(`/stripe_integrations/subscription/payment_methods${queryParam}`, {
      payment_method_id: token
    })
      .then(params => {
        const status = _get(params, "status", 400);
        if (status < 300) {
          dispatch(partialSettingsUpdated({ stripeCustomerId: true }));
        }

        dispatch(
          setAlertMessage(`Your Card Has Been ${hasCard ? "Updated" : "Added"} Successfully!`, "success")
        );
        // Facebook Pixel - conversion (AddPaymentInfo)
        ReactPixel.track("AddPaymentInfo");

        trackAdsByRedux({
          from: "facebook",
          action: "AddPaymentInfoUTM"
        });

        // Google Analytics
        gaEvent({
          category: "Upgrade",
          action: "AddPaymentInfo"
        });

        trackAdsByRedux({
          from: "google",
          category: "Upgrade",
          action: "AddPaymentInfoUTM"
        });
      })
      .catch(error => {
        if (error.status) {
          dispatch(setAlertMessage(error.json.errors.stripe_card[0], "error"));
        } else {
          throw error;
        }
      });
  };
}

export function updatePaymentSettings(token, type, hasCard) {
  return function(dispatch) {
    const queryParam = type === "v2" ? "?account_source=new" : "";
    return ApiCall.post(`/stripe_integrations/payment_methods${queryParam}`, {
      payment_method_id: token
    })
      .then(params => {
        const status = _get(params, "status", 400);
        if (status < 300) {
          dispatch(partialSettingsUpdated({ stripeCustomerId: true }));
        }

        dispatch(
          setAlertMessage(`Your Card Has Been ${hasCard ? "Updated" : "Added"} Successfully!`, "success")
        );
        // Facebook Pixel - conversion (AddPaymentInfo)
        ReactPixel.track("AddPaymentInfo");

        trackAdsByRedux({
          from: "facebook",
          action: "AddPaymentInfoUTM"
        });

        // Google Analytics
        gaEvent({
          category: "Upgrade",
          action: "AddPaymentInfo"
        });

        trackAdsByRedux({
          from: "google",
          category: "Upgrade",
          action: "AddPaymentInfoUTM"
        });
      })
      .catch(error => {
        if (error.status) {
          dispatch(setAlertMessage(error.json.errors.stripe_card[0], "error"));
        } else {
          throw error;
        }
      });
  };
}

export function downgradePlan({
  cancelReason = "",
  storeId,
  cancel_likelihood_of_return_rating = "",
  cancel_comment = ""
}) {
  return function(dispatch) {
    dispatch(isDowngrading(true));
    return ApiCall.post(`/stores/${storeId}/plan_downgrade`, {
      cancel_reason: cancelReason,
      cancel_now: true,
      cancel_likelihood_of_return_rating,
      cancel_comment: cancel_comment
    })
      .then(res => {
        const { id: subscriptionId } = res.json;
        dispatch(showModal("DOWNGRADE_SUCCESS", { subscriptionId }));
        // Google Analytics
        gaEvent({
          category: "Billing",
          action: "Downgrade"
        });
        dispatch(isDowngrading(false));
      })
      .catch(error => {
        if (error.status) {
          dispatch(setAlertMessage("An error occurred while cancelled, please contact us!", "error"));
        } else {
          throw error;
        }
        dispatch(isDowngrading(false));
      });
  };
}

export function updateSettingsFromExternal(data) {
  return function(dispatch) {
    dispatch(partialSettingsUpdated(data));
  };
}

function collectionsSet(data) {
  return {
    type: COLLECTIONS_SET,
    data: data
  };
}

function squareLocationsSet(data) {
  return {
    type: SQUARE_LOCATIONS_SET,
    data: data
  };
}

function allSettingsUpdated(data) {
  return {
    type: SETTINGS_UPDATED_ALL,
    data: data
  };
}

function partialSettingsUpdated(values) {
  // Needs to match the values in the Reducer!!
  return {
    type: SETTINGS_UPDATED_PARTIAL,
    values: values
  };
}

export function isDowngrading(isDowngrading) {
  return {
    type: IS_DOWNGRADING,
    payload: {
      isDowngrading: isDowngrading
    }
  };
}

export function fetchDropshipperData() {
  return function(dispatch) {
    return ApiCall.get("/data").then(params => {
      const json = _get(params, "json", {});
      dispatch(fetchDropshipperDataSuceess(json));
    });
  };
}

function fetchDropshipperDataSuceess(data) {
  return {
    type: DROPSHIPPER_DATA_SUCCESS,
    data
  };
}

export function setCollectionSearch(status) {
  return {
    type: SET_COLLECTION_SEARCH,
    status
  };
}

export function fetchEmpireProrationPrice() {
  return function(dispatch) {
    return ApiCall.get("/empire_proration_price").then(params => {
      const json = _get(params, "json", {});
      dispatch(fetchEmpireProrationPriceSuccess(json));
    });
  };
}

function fetchEmpireProrationPriceSuccess(data) {
  return {
    type: EMPIRE_PRORATION_PRICE_SUCCESS,
    data
  };
}
