import ApiCall from "utils/apiCall";

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case "store.personalization.collections.isFetching":
      return { ...state, isFetching: true };

    case "store.personalization.collections.fetchSuccess":
      return { ...state, isFetching: false, plans: action.response };

    case "store.personalization.collections.fetchError":
      return { ...state, isFetching: false, plans: action.response };

    case "store.personalization.isFetching":
      return { isFetching: true };

    case "store.personalization.fetchSuccess":
      return { isFetching: false };

    case "store.personalization.fetchError":
      return { isFetching: false };

    default:
      return state;
  }
}

// Actions
export function setPersonalization(params) {
  return async dispatch => {
    dispatch({ type: "store.personalization.isFetching" });
    try {
      const response = await ApiCall.put("/stores/personalization", params);

      dispatch({
        type: "store.personalization.fetchSuccess",
        response
      });
    } catch (error) {
      dispatch({
        type: "store.personalization.fetchError",
        error
      });
    }
  };
}

export async function updatePersonalization(params) {
  return await ApiCall.put("/stores/personalization", params);
}
