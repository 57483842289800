// React and Redux
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Utils
import { analytics } from "utils/analytics";

// Routes
import paths from "routes/paths";

// Actions
import { signInUser } from "utils/api/user/requests";
import {
  setupCredentialStorage,
  updateSettingsFromCredentials,
  signIn,
  register,
  verify,
  verifyAuth
} from "utils/actions";

// Utils
import { isAuthenticated } from "utils/features";
import { useNavigate, useLocation } from "react-router-dom";

export default RequireAuth => {
  const Auth = ({ verify }) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search) || {};
    const authToken = params.get("auth_token");
    const currentToken = isAuthenticated();
    const [checkedToken, setCheckedToken] = useState(false);
    const [tokenValidated, setTokenValidated] = useState(false);

    const accessDenied = () => {
      saveUrlToRedirectAfterLogin();
      navigate(paths.auth.login);
    };

    const saveUrlToRedirectAfterLogin = () => {
      if (pathname !== paths.auth.login) {
        localStorage.setItem("redirect_to_url_after_signin", pathname);
      }
    };

    const authTokenLogin = async () => {
      // Get query params
      const queryParams = new URLSearchParams(search);
      const jsonQueryParams = Object.fromEntries(queryParams);
      let queryStrings = "";
      Object.keys(jsonQueryParams)
        .filter(key => key !== "auth_token")
        .forEach((key, index) => {
          queryStrings += `${index === 0 ? "?" : "&"}${key}=${jsonQueryParams[key]}`;
        });

      // Use token to login
      signInUser({ authToken })
        .then(res => {
          const { store_credential: storeCredential } = res;
          const { storeId } = storeCredential;
          setTokenValidated(true);
          setCheckedToken(true);
          setupCredentialStorage(res);
          updateSettingsFromCredentials(res);
          signIn();
          register();
          verifyAuth();
          // Redirection logic
          navigate(pathname + queryStrings);
          localStorage.removeItem("redirect_to_url_after_signin");
          // Segment - Identify user when they sign up (Signup)
          analytics.identify(storeId, { source: "token" });
          // Segment - conversion (Signup)
          analytics.track("login_complete", { storeId: storeId, source: "token" });
        })
        .catch(() => {
          accessDenied();
        });
    };

    useEffect(() => {
      if (authToken) {
        // If token is different from current token and user has current token, it means user is already logged in, so just verify them
        if (authToken !== currentToken && currentToken !== null) {
          setCheckedToken(true);
          verify(navigate);
        } else {
          authTokenLogin();
        }
      } else if (currentToken === null) {
        setCheckedToken(true);
        accessDenied();
      } else {
        setCheckedToken(true);
        verify(navigate);
      }
    }, []);

    if (checkedToken && tokenValidated) {
      return <RequireAuth />;
    } else {
      return isAuthenticated() ? <RequireAuth /> : null;
    }
  };

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ verify }, dispatch);
  }

  return connect(null, mapDispatchToProps)(Auth);
};
