const initialState = {
  currentModal: null,
  selectedProduct: null
};

const UiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        currentModal: action.payload.currentModal,
        data: action.payload.data
      };
    case "SET_SELECTED_PRODUCT":
      return {
        ...state,
        selectedProduct: action.payload.selectedProduct
      };
    case "CLEAR_SELECTED_PRODUCT":
      return {
        ...state,
        selectedProduct: null
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        currentModal: action.payload.currentModal
      };
    default:
      return state;
  }
};

export default UiReducer;
