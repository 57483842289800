import ApiCall from "utils/apiCall";

const initialState = {
  firstTenNotifications: [],
  unreadNotifications: [],
  isFetching: false,
  currentPage: 1,
  totalPages: 1,
  feed: []
};
// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "feeds.isFetching":
      return { ...state, isFetching: true };

    case "feeds.setFirstTenNotifications":
      return {
        ...state,
        firstTenNotifications: action.response.feed.slice(0, 10)
      };

    case "feeds.fetchSuccess":
      const {
        response: { feed, total_entries: totalEntries, total_pages: totalPages },
        currentPage
      } = action;

      return {
        ...state,
        isFetching: false,
        newNotification: isThereAnyNewNotification(feed),
        unreadNotifications: getAllUnreadNotificationsId(feed),
        feed,
        totalEntries,
        totalPages,
        currentPage
      };

    case "feeds.fetchError":
      return { ...state, isFetching: false, feed: action.response };

    default:
      return state;
  }
}

// Actions
export function getNotifications(page) {
  return async dispatch => {
    dispatch({ type: "feeds.isFetching" });
    try {
      const response = await ApiCall.get("/feeds", { ...page, per_page: 25 });

      if (page.page === 1) {
        dispatch({
          type: "feeds.setFirstTenNotifications",
          response: response.json
        });
      }

      dispatch({
        type: "feeds.fetchSuccess",
        response: response.json,
        currentPage: page.page
      });
    } catch (error) {
      dispatch({ type: "feeds.fetchError", error });
    }
  };
}

export function setNotificationAsViewed(notificationIds) {
  return async dispatch => {
    try {
      await ApiCall.post(`/feeds/mark_as_viewed?notification_ids=${notificationIds}`);
      dispatch(getNotifications({ page: 1 }));
    } catch (error) {
      console.log(error);
    }
  };
}

// Utils - Private

function isThereAnyNewNotification(notifications) {
  return notifications.filter(notification => notification.viewed_at === null).length > 0;
}

function getAllUnreadNotificationsId(notifications) {
  return notifications
    .filter(notification => notification.viewed_at === null)
    .map(notification => notification.notification_id);
}
