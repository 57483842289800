import React, { lazy } from "react";

import { QueryParamProvider, StringParam, useQueryParams } from "use-query-params";

import RequireAuth from "routes/requirements/RequireAuth";
import RequireShop from "routes/requirements/RequireShop";
import RequireNonAmazonAccount from "routes/requirements/RequireNonAmazonAccount";
import RequireNotBlockedRouteChange from "routes/requirements/RequireNotBlockedRouteChange";

import routes from "./routes";
import redirects from "./redirects";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { isSpocketUser } from "utils/email";

import { ErrorPage } from "pages/ErrorPage";
const Navigation = lazy(() => import("components/Navigation"));

const navWrapper = Component => props => {
  const [query] = useQueryParams({
    pricing: StringParam,
    show_rating: StringParam
  });

  return (
    <Navigation {...props} pricing={query.pricing} showRating={query.show_rating}>
      <Component />
    </Navigation>
  );
};

const WrapperUseQueryParams = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <QueryParamProvider
      history={{ replace: navigate, push: navigate }}
      location={location}
      adapter={ReactRouter6Adapter}
    >
      {children}
    </QueryParamProvider>
  );
};

export const makeRedirects = () => {
  return redirects.map(({ from, to }) => ({
    path: from,
    element: <Navigate to={to} replace />
  }));
};

export const makeRoutes = () => {
  const isSpocketUserVal = isSpocketUser();

  return [
    ...routes
      .map(({ path, Component, app, shop, blockedForAmazonUsers, spocketUser }) => {
        if (app) {
          if (isSpocketUserVal) {
            Component = RequireAuth(navWrapper(Component));
          } else {
            // Sometimes users might be blocked on Search page so we should not allow them to go to other routes
            Component = RequireAuth(RequireNotBlockedRouteChange(navWrapper(Component)));
          }
        }

        if (shop) {
          Component = RequireShop(Component);
        }

        if (blockedForAmazonUsers) {
          Component = RequireNonAmazonAccount(Component);
        }

        // If route is set to be used by Spocket user and user is not Spocket, return null
        if (spocketUser !== undefined && spocketUser !== isSpocketUserVal) return null;

        return {
          path,
          element: (
            <WrapperUseQueryParams>
              <Component />
            </WrapperUseQueryParams>
          ),
          errorElement: isSpocketUserVal ? undefined : "Something has gone wrong, please contact support!"
        };
      })
      .filter(t => t),
    { errorElement: <ErrorPage /> }
  ];
};
