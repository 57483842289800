import ApiCall from "utils/apiCall";
import { setAlertMessage } from "utils/actions";
import { gaEvent } from "utils/trackEvents";

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case "store.pause.isFetching":
      return { ...state, isFetching: true };

    case "store.pause.fetchSuccess":
      return { ...state, isFetching: false, plans: action.response };

    case "store.pause.fetchError":
      return { ...state, isFetching: false, plans: action.response };

    default:
      return state;
  }
}

// Actions
export function pauseStore() {
  return async dispatch => {
    dispatch({ type: "store.pause.isFetching" });
    try {
      const response = await ApiCall.put("/store/pause");
      dispatch({
        type: "store.pause.fetchSuccess",
        response: response.json
      });

      // Google Analytics
      gaEvent({
        category: "Billing",
        action: "Pause your plan"
      });
    } catch (error) {
      dispatch({ type: "store.pause.fetchError", error });
      dispatch(setAlertMessage(error.json.errors, "error"));
    }
  };
}
