// Utils
import ApiCall from "utils/apiCall";
import { setAlertMessage } from "utils/actions";
import { gaEvent } from "utils/trackEvents";

const initialState = {
  paymentConfirmationRequired: false,
  paymentConfirmationUrl: null
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.sampleOrder.paymentConfirmation.required":
      return {
        ...state,
        paymentConfirmationRequired: true,
        paymentConfirmationUrl: action.confirmationUrl
      };
    case "store.sampleOrder.paymentConfirmation.clean":
      return {
        ...state,
        paymentConfirmationRequired: false,
        paymentConfirmationUrl: null
      };
    default:
      return state;
  }
}

// Actions

export function placeSampleOrder(data, successCallback, failureCallback) {
  return dispatch => {
    ApiCall.post("/stores/orders/sample/pay", { ...data })
      .then(({ json }) => {
        if (json.confirmation_url) {
          dispatch({
            type: "store.sampleOrder.paymentConfirmation.required",
            confirmationUrl: json.confirmation_url
          });
        }
        gaEvent({
          category: "Orders",
          action: "Processed a Sample Order"
        });
        successCallback();
      })
      .catch(({ json }) => {
        failureCallback();
        dispatch(setAlertMessage(json.errors, "error"));
      });
  };
}

export function placeOrder(integratedStoreOrderID, supplierID, note, successCallback, failureCallback) {
  return dispatch => {
    ApiCall.post(`/stores/orders/${integratedStoreOrderID}/pay/${supplierID}`, {
      note
    })
      .then(({ json }) => {
        if (json.confirmation_url) {
          dispatch({
            type: "store.sampleOrder.paymentConfirmation.required",
            confirmationUrl: json.confirmation_url
          });
        }
        gaEvent({
          category: "Orders",
          action: "Processed an Order"
        });
        successCallback();
      })
      .catch(({ json }) => {
        json.errors.forEach(error => {
          failureCallback(error);
          dispatch(setAlertMessage(error, "error"));
        });
      });
  };
}

export function cleanConfirmation() {
  return dispatch => {
    dispatch({
      type: "store.sampleOrder.paymentConfirmation.clean"
    });
  };
}
