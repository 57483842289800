import ApiCall from "utils/apiCall";
import { signupShop } from "utils/actions/index";

const initialState = {
  isFetching: false,
  email: null
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "signUp.isFetching":
      return { ...state, isFetching: true };

    case "signUp.fetchSuccess":
      return { ...state, isFetching: false, ...action.response };

    case "signUp.fetchError":
      return { ...state, isFetching: false, ...action.response };

    case "signUp.setEmail":
      return { ...state, email: action.response.email };

    default:
      return state;
  }
}

// Actions
export function signUp(credentials, actions, navigate) {
  const { email, name, topCategories } = credentials;

  const requestPayload = {
    email,
    name,
    stores_attributes: [
      {
        name: null
      }
    ]
  };

  return dispatch => {
    dispatch({ type: "signUp.isFetching" });

    ApiCall.post("/sign_up", requestPayload)
      .then(response => {
        dispatch({
          type: "signUp.fetchSuccess",
          response: response.json
        });
        dispatch(signupShop({ action: "signed_up", ...response.json, topCategories }, false, navigate));
      })
      .catch(error => {
        dispatch({
          type: "signUp.fetchError",
          response: error.json
        });

        // actions comes from Formik
        actions.setStatus(setError(error.json));
        actions.setSubmitting(false);
      });
  };
}

function setError(response) {
  const key = Object.keys(response.error)[0];

  if (key === "email") {
    return { email: "This email already exists." };
  }
}

// When user uses auto-login on signup page we save the email to be used on login page
export function setLoginEmail(email) {
  return dispatch => {
    dispatch({
      type: "signUp.setEmail",
      response: {
        email
      }
    });
  };
}
