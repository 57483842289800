import ApiCall from "utils/apiCall";

const initialState = {
  account: {
    name: "",
    integrated_stores: [],
    utm: {
      utm_source: "",
      utm_medium: ""
    },
    review: { created_at: "" }
  }
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.isFetching":
      return { ...state, isFetching: true };

    case "store.fetchSuccess":
      return {
        ...state,
        isFetching: false,
        account: {
          ...action.response,
          utm: action.response.utm || state.account.utm,
          review: action.response.review || state.account.review
        }
      };

    case "store.fetchError":
      return { ...state, isFetching: false, account: action.response };

    default:
      return state;
  }
}

// Actions
export function getStore() {
  return async dispatch => {
    dispatch({ type: "store.isFetching" });
    try {
      const response = await ApiCall.get("/store");

      dispatch({
        type: "store.fetchSuccess",
        response: response.json
      });
    } catch (error) {
      dispatch({ type: "store.fetchError", error });
    }
  };
}
