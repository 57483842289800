import React from "react";
import { connect } from "react-redux";

export default RequiredShop => {
  const Shop = ({ registered, props }) => {
    const hasShop = () => {
      if (!registered) {
        if (localStorage.shop_id === null) {
          return false;
        }
        return true;
      }
      return true;
    };

    return hasShop() ? <RequiredShop data-testid="required-shop" {...props} /> : false;
  };

  function mapStateToProps(state) {
    return { registered: state.auth.registered || "" };
  }

  return connect(mapStateToProps)(Shop);
};
