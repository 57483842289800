import React from "react";
import PropTypes from "prop-types";

import * as S from "./commonLoadable.style";

function CommonLoadable(props) {
  const { active, text, spinnerSize, background, children, testId, isChildren } = props;

  // Only render overlay, spinner and text if active is true
  const renderSpinner = () => {
    if (!active) return null;
    return (
      <S.Overlay bgColor={background} data-testid={`loading-icon-${testId}`}>
        <S.Spinner spinnerSize={spinnerSize}>
          <S.Circle1 spinnerSize={spinnerSize} />
          <S.Circle2 spinnerSize={spinnerSize} />
          <S.Circle3 spinnerSize={spinnerSize} />
        </S.Spinner>
        {text && <S.Text>{text}</S.Text>}
      </S.Overlay>
    );
  };

  return (
    <S.Container isChildren={isChildren} className="loading-spinner-container">
      {renderSpinner()}
      {children}
    </S.Container>
  );
}

CommonLoadable.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.string,
  spinnerSize: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string
};

CommonLoadable.defaultProps = {
  testId: ""
};

export default CommonLoadable;
