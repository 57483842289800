/* eslint no-eval: 0 */
import React from "react";
import { useEffect } from "react";
import gtmParts from "react-google-tag-manager";

export const GoogleTagManager = ({ scriptId, gtmId, dataLayerName, additionalEvents }) => {
  const sId = scriptId || "react-google-tag-manager-gtm";

  useEffect(() => {
    const gtmScriptNode = document.getElementById(sId);
    eval(gtmScriptNode.textContent);
  }, []);

  const gtm = gtmParts({
    id: gtmId,
    dataLayerName: dataLayerName || "dataLayer",
    additionalEvents: additionalEvents || {}
  });

  return (
    <div>
      <div>{gtm.noScriptAsReact()}</div>
      <div id={sId}>{gtm.scriptAsReact()}</div>
    </div>
  );
};
