// Libs
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";

// Moment - List of locales https://stackoverflow.com/a/55827203
import moment from "moment";
import baseLanguage from "utils/i18n/en.json";

const REMOTE_TRANSLATIONS_URL = "https://spocket-co-localization-files.s3.us-east-2.amazonaws.com";
const MAIN_LANGUAGE = "en";

/**
 * ChainedBackend uses a mix from local and remote translations.
 * So from en we are reading from locally and the other ones from remote
 */

const LANGUAGES_LIST = {
  it: `${REMOTE_TRANSLATIONS_URL}/src/utils/i18n/en+(it).json`,
  pt: `${REMOTE_TRANSLATIONS_URL}/src/utils/i18n/en+(pt-BR).json`,
  fr: `${REMOTE_TRANSLATIONS_URL}/src/utils/i18n/en+(fr).json`,
  es: `${REMOTE_TRANSLATIONS_URL}/src/utils/i18n/en+(es-ES).json`,
  de: `${REMOTE_TRANSLATIONS_URL}/src/utils/i18n/en+(de).json`
};

// We shouldn't add en as a remote, otherwise ChainedBackend wont be able to load locally translation
const localResources = {
  en: {
    translation: {
      ...baseLanguage
    }
  }
};

i18n
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: localStorage.getItem("language") || MAIN_LANGUAGE,
    fallbackLng: MAIN_LANGUAGE,
    react: {
      useSuspense: true
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already protects from xss
      format: function(value, format, lng) {
        if (value instanceof Date)
          return moment(value)
            .locale(lng)
            .format(format);
        return value;
      }
    },
    ns: ["translation"],
    backend: {
      backends: [HttpBackend, resourcesToBackend(localResources)],
      backendOptions: [
        {
          loadPath(languages) {
            const languageToUse = languages[0];
            if (languageToUse !== MAIN_LANGUAGE) {
              return `${LANGUAGES_LIST[languageToUse]}`;
            }
          },
          allowMultiLoading: false,
          crossDomain: true
        }
      ]
    }
  });

export default i18n;
