import ReactGA from "react-ga4";

const itIsProductionOrStagingEnv =
  process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging";

export function gaEvent({ category, action, label, options = {} }) {
  try {
    if (itIsProductionOrStagingEnv) {
      ReactGA.event({ category, action, label, options });
    }
  } catch (err) {
    console.log(err);
  }
}
