import React, { Suspense } from "react";
import Loadable from "components/_Shared/commonLoadable";

import { makeRoutes, makeRedirects } from "./utils";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const routes = makeRoutes();
const redirects = makeRedirects();

const router = createBrowserRouter([...routes, ...redirects]);

const Routes = props => {
  return (
    <Suspense fallback={<Loadable active isChildren />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
