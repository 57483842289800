import Cookies from "js-cookie";
import ReactPixel from "react-facebook-pixel";
import { store } from "utils/store/configureStore";
import { gaEvent } from "./trackEvents";

/**
 * @param  { String } utms
 * @return String
 */
export function formatUtm(utms) {
  const arrayOfUtms = utms
    .split("?")[1]
    .split("&")
    .map(utm => {
      const splitedUtm = utm.split("=");
      const key = splitedUtm[0];
      const value = splitedUtm[1];
      return { [key]: value };
    });

  return Object.assign({}, ...arrayOfUtms);
}

/**
 * Solely based on cookies
 *
 * If we detect that the user has a UTM attributed, then we can fire an event.
 *
 * @param  {string} from
 * @param  {string} action
 * @param  {string} category
 * @returns {TrackEvent}
 */
export function trackAdsByCookie({ from, action, category }) {
  const spocketUtm = Cookies.get("spocket_utm") || "";
  const isThisValidUTM = utmName => spocketUtm.indexOf(utmName) > 0;

  switch (from.toLocaleLowerCase()) {
    case "facebook":
      if (isThisValidUTM("facebook_ads")) {
        ReactPixel.trackCustom(action);
      }
      break;

    case "google":
      if (isThisValidUTM("google") || isThisValidUTM("cpc")) {
        gaEvent({
          category,
          action
        });
      }
      break;

    default:
      return;
  }
}

/**
 * Triggers based on existing UTM data in Redux
 *
 * If we detect that the user has a UTM attributed, then we can fire an event.
 *
 * @param  {string} from
 * @param  {string} action
 * @param  {string} category
 * @returns {TrackEvent}
 */
export function trackAdsByRedux({ from, action, category, option }) {
  const spocketUtm = store.getState().store.information.account.utm;
  const { utm_source, utm_medium } = spocketUtm;

  switch (from.toLocaleLowerCase()) {
    case "facebook":
      if (utm_source === "facebook_ads") {
        ReactPixel.trackCustom(action, option);
      }
      break;

    case "google":
      if (utm_source === "google" || utm_medium === "cpc") {
        gaEvent({
          category,
          action
        });
      }
      break;

    default:
      return;
  }
}

// Private
