import ApiCall from "utils/apiCall";
import { setAlertMessage } from "utils/actions";
import { showModal } from "utils/actions/ui";
import { getPlans } from "../plans";
import { verify } from "utils/actions/index";

import paths from "routes/paths";

// Services
import { FETCH_SETTINGS_QUERY } from "utils/api/settings/types";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.paypal.paymentSuccess.isFetching":
      return { ...state, isFetching: true };

    case "store.paypal.paymentSuccess.fetchSuccess":
      return { ...state, isFetching: false, plans: action.response };

    case "store.paypal.paymentSuccess.fetchError":
      return { ...state, isFetching: false, plans: action.response };

    default:
      return state;
  }
}

// Actions

export function activePayPalCharge(token, navigate, queryClient) {
  return (dispatch, getState) => {
    dispatch({ type: "store.paypal.paymentSuccess.isFetching" });
    return dispatch(getPlans()).then(() => {
      const apiAction = ApiCall.get(`/stores/paypal/payment_success?token=${token}`);
      apiAction
        .then(({ json }) => {
          dispatch(verify());
          queryClient.invalidateQueries([FETCH_SETTINGS_QUERY]);
          const { allPlans } = getState().store.plans;
          const plan = allPlans.find(plan => plan.id === json.plan_id);
          if (!plan) {
            return;
          }
          if (plan.annual) {
            dispatch(showModal("UPGRADE_SUCCESS", { plan }));
          } else {
            dispatch(showModal("ANNUAL_PROMOTION_MODAL", { plan }));
          }
        })
        .catch(error => {
          dispatch({ type: "store.paypal.paymentSuccess.fetchError", error });
          dispatch(setAlertMessage("Something went wrong. Please, contact our support.", "error"));
          navigate(paths.settings.plans);
        });
    });
  };
}
