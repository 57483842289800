import { lazy } from "react";
import paths from "../paths";

const NonAuth = lazy(() => import("pages/NonAuth/index").then(module => ({ default: module.NonAuth })));
const NonAuthPayment = lazy(() =>
  import("pages/NonAuthPayment/index").then(module => ({ default: module.NonAuthPayment }))
);

export const nonAuthRoutes = [
  { path: paths.nonAuth.search, Component: NonAuth, app: false },
  { path: paths.nonAuth.payment, Component: NonAuthPayment, app: false }
];
