// Actions
import { setAlertMessage } from "utils/actions";
import { gaEvent } from "utils/trackEvents";
// Utils
import ApiCall from "utils/apiCall";
import { showModal } from "utils/actions/ui";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.orders.pay.isFetching":
      return { ...state, isFetching: true };

    case "store.orders.pay.fetchSuccess":
      return { ...state, isFetching: false, plans: action.response };

    case "store.orders.pay.fetchError":
      return { ...state, isFetching: false, plans: action.response };

    default:
      return state;
  }
}

// Actions
export function placeOrders(selectedOrders) {
  return async dispatch => {
    dispatch({ type: "store.orders.pay.isFetching" });
    try {
      const response = await ApiCall.post("/stores/orders/pay", {
        integrated_store_orders: formatIntegratedStoreOrderParams(selectedOrders)
      });
      gaEvent({
        category: "Orders",
        action: "Bulk Checkout"
      });
      dispatch({
        type: "store.orders.pay.fetchSuccess",
        response: response.json
      });
      dispatch(showModal("AFTER_CONFIRM_CHECKOUT_ORDER_MODAL"));
    } catch (error) {
      dispatch({ type: "store.orders.pay.fetchError", error });
      dispatch(setAlertMessage(error.json.errors, "error"));
    }
  };
}

// Utils

function formatIntegratedStoreOrderParams(selectedOrders) {
  return selectedOrders.map(selectedOrder => {
    const suppliersIds = selectedOrder.supplier_orders
      .filter(supplierOrder => supplierOrder.id === null)
      .map(order => order.supplier_id);

    return {
      order_id: selectedOrder.id,
      suppliers_ids: suppliersIds
    };
  });
}
