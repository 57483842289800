import ApiCall from "utils/apiCall";

const initialState = {
  isFetching: false,
  startDate: "",
  endDate: "",
  orderStatus: "total_orders"
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "analytics.salesAnalysis.isFetching":
      return { ...state, isFetching: true };

    case "analytics.salesAnalysis.fetchSuccess":
      return { ...state, isFetching: false, ...action.response };

    case "analytics.salesAnalysis.fetchError":
      return { ...state, isFetching: false, ...action.response };

    case "analytics.salesAnalysis.updateDate":
      return { ...state, ...action.date };

    case "analytics.salesAnalysis.updateOrderStatus":
      return { ...state, orderStatus: action.response };

    default:
      return state;
  }
}

// Actions
export function getSalesAnalysis(
  startDate = initialState.startDate,
  endDate = initialState.endDate,
  orderStatus = initialState.orderStatus
) {
  return dispatch => {
    dispatch({ type: "analytics.salesAnalysis.isFetching" });
    dispatch(updateDate(startDate, endDate));
    dispatch(updateOrderStatus(orderStatus));

    ApiCall.post(`/sales_analysis`, {
      analytics: {
        start_date: startDate,
        end_date: endDate,
        order_status: orderStatus
      }
    })
      .then(response => {
        dispatch({
          type: "analytics.salesAnalysis.fetchSuccess",
          response: response.json
        });
      })
      .catch(error => {
        dispatch({ type: "analytics.salesAnalysis.fetchError", error });
      });
  };
}

export function updateDate(startDate, endDate) {
  return {
    type: "analytics.salesAnalysis.updateDate",
    date: {
      startDate,
      endDate
    }
  };
}

export function updateOrderStatus(orderStatus) {
  return {
    type: "analytics.salesAnalysis.updateOrderStatus",
    response: orderStatus
  };
}
