import { lazy } from "react";
import paths from "../paths";

const PlanRedirect = lazy(() => import("components/PlanRedirect"));
const Academy = lazy(() => import("pages/academy").then(module => ({ default: module.Academy })));
const SpocketDeals = lazy(() => import("pages/spocketDeals"));

export const promotionRoutes = [
  { path: paths.promotions.planRedirect, Component: PlanRedirect, app: true },
  { path: paths.promotions.academy, Component: Academy, app: true, shop: true },
  { path: paths.promotions.spocketDeals, Component: SpocketDeals, app: true, shop: true }
];
