import { sendGet, sendPost, sendPut, sendPatch, sendPostWithoutSignOut } from "../baseUtils";

export const changePassword = credentials => sendPatch("/", credentials);

export const getUserVerifyData = () =>
  sendGet("/verify").then(res => {
    return res.data;
  });

export const registerAliexpressToken = async ({ aliexpressToken }) =>
  sendPost("/aliexpress/load", { aliexpress_token: aliexpressToken });

export const sendOnboardingFlowOptions = async ({
  selectedCategories: categories,
  selectedProductsKind: kind,
  selectedRegion: sales_region,
  createStoreOption: create_store_on_provider,
  selectedColorScheme: color_scheme,
  selectedPersonality: personality
}) =>
  sendPut("/stores/preference", {
    categories,
    color_scheme,
    kind,
    sales_region,
    create_store_on_provider,
    personality
  });

export const resetPassword = async ({ email, hCaptchaToken }) =>
  sendGet("/password/new", {
    email,
    "h-captcha-response": hCaptchaToken
  });

export const verifyPassword = async ({ password }) =>
  sendPost("/password/verify", {
    password
  });

export const signInUser = async ({ email, password, hCaptchaToken, authToken }) =>
  sendPostWithoutSignOut("/sign_in", {
    email,
    password,
    "h-captcha-response": hCaptchaToken,
    auth_token: authToken
  }).then(res => {
    return res.data;
  });

export const signUp = async ({ userName, email, hCaptchaToken }) =>
  sendPostWithoutSignOut("/sign_up", {
    email,
    name: userName,
    "h-captcha-response": hCaptchaToken,
    stores_attributes: [
      {
        name: null
      }
    ]
  });

export const changeName = async ({ name }) => sendPut("/data/dropshipper", { name });

export const changeEmail = async ({ email }) => sendPost("/mail_verification", { email });

export const changeEmailConfirm = async ({ email, verificationCode }) =>
  sendPost("/mail_verification/confirm", {
    email,
    verification_code: verificationCode
  });
