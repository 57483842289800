const grey = {
  colorGrey60: "#222939",
  colorGrey55: "#575c66",
  colorGrey50: "#6f7582",
  colorGrey40: "#ccd2e1",
  colorGrey35: "#DBE0EB",
  colorGrey30: "#e6e8f0",
  colorGrey25: "#f9fafb",
  colorGrey20: "#f4f5f8",
  colorGrey10: "#fafbfc",
  colorGreyDivider: "#ECE5E6"
};

const green = {
  colorGreen60: "#21543d",
  colorGreen50: "#33845e",
  colorGreen48: "#32905e",
  colorGreen46: "#1ca140",
  colorGreen45: "#38a169",
  colorGreen40: "#20b94a",
  colorGreen35: "#66CC76",
  colorGreen30: "#69d391",
  colorGreen20: "#9be6b4",
  colorGreen15: "#E2FAEA",
  colorGreen10: "#e5fbec"
};

const purple = {
  colorPurple40: "#302371",
  colorPurple30: "#571abc",
  colorPurple20: "#712de2",
  colorPurple15: "#784ddd",
  colorPurple11: "#bab1ff",
  colorPurple10: "#8144e5",
  colorPurple05: "#F1EDFC",
  colorLightPurple09: "#7b61ff12"
};

const blue = {
  colorBlue05: "#D7ECFF",
  colorBlue10: "#081f40",
  colorBlue20: "#3751FF",
  colorBlueWebsiteBg: "#F6F9FF",
  colorLightBlue: "#081F40B2",
  colorLightBlueSocial: "#4385F5",
  colorDarkBlueSocial: "#3B5998"
};

const brown = {
  colorBrown10: "#BE9B9B",
  colorBrown20: "#403533",
  colorBrown30: "#362E2C"
};

const yellow = {
  colorYellow10: "#fbf8e5",
  colorYellow20: "#FFF4D7",
  colorYellow30: "#FFCD29"
};

const black = {
  colorBlack10: "#2e3039",
  colorBlack20: "#000000",
  colorBlack30: "#1A1919",
  colorBlack40: "#000F2B",
  colorBlack50: "#070B1D",
  colorBlack300: "#4b5b72"
};

const white = {
  colorWhite: "#ffffff"
};

const pink = {
  colorPink5: "#F8F5F4",
  colorPink10: "#F4ECEC",
  colorPink20: "#FBF9F0",
  colorPink500: "#DB5F7A",
  colorPink600: "#BF526A"
};

const red = {
  colorWarning: "#e24444",
  colorWarningDark: "#b52f2f",
  colorRed40: "#d53f3f"
};

const orange = {
  colorOrange20: "#FFD9D4",
  colorOrange40: "#866e42",
  colorOrange60: "#ff7100"
};

const shadow = {
  colorLightPurple007: "#784ddd07", // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
  colorLightBlue: "#081f4020"
};

// Just call this function with one of the colors above and the percentage of opacity
// Ex: background-color: ${props => props.theme.colors.addOpacity({ color: props.theme.colors.red.colorWarning, opacity: 0.1 })};
const addOpacity = ({ color, opacity }) => {
  const colorOpacityMap = {
    0.05: color + "0D",
    0.1: color + "1A",
    0.2: color + "33",
    0.3: color + "4D",
    0.4: color + "66",
    0.5: color + "80",
    0.6: color + "99",
    0.7: color + "B3",
    0.9: color + "E6"
  };
  return colorOpacityMap[opacity] || color;

  // Add more if necessary (https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4)
};

const colors = {
  black,
  green,
  purple,
  grey,
  white,
  pink,
  red,
  orange,
  blue,
  brown,
  yellow,
  shadow,
  addOpacity
};

export default colors;
