const spaces = {
  space80: "256px",
  space70: "128px",
  space60: "64px",
  space50: "32px",
  space40: "24px",
  space30: "16px",
  space20: "8px",
  space10: "4px"
};

export default spaces;
