export const formatErrorMessage = e => {
  if (!e) {
    return "Unknown Error";
  }
  // Case the error has response
  if (e.response && e.response.data) {
    const {
      data: { errors }
    } = e.response;
    if (typeof errors === "string" || errors instanceof String) {
      return errors;
    }
  }
  // Case the error has single error response
  if (e.response && e.response.data) {
    const {
      data: { error, errors }
    } = e.response;
    if (typeof error === "string" || error instanceof String) {
      return error;
    }
    if (typeof errors === "string" || errors instanceof String) {
      return errors;
    }
    if (Array.isArray(errors)) {
      return errors.join(", ");
    }
  }
  // Case the error is a standard Javascript Error (https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Error)
  if (e.message) {
    return e.message;
  }
  // Case the error comes from API/Backend
  if (e.json && e.json.errors) {
    const { errors } = e.json;
    if (typeof errors === "string" || errors instanceof String) {
      return errors;
    }
    if (Array.isArray(errors)) {
      return errors.join(", ");
    }
  }
  // Case the error comes from api.js
  if (e.errors) {
    const { errors } = e;
    if (typeof errors === "string" || errors instanceof String) {
      return errors;
    }
    if (Array.isArray(errors)) {
      return errors.join(", ");
    }
  }
  // Case the error is an object, but we don't know the format, we will encode it as JSON string
  return JSON.stringify(e);
};

const searchForString = (string, substring) => string.includes(substring);

// Change error message text based on https://docs.google.com/document/d/1KJPwOZgS5yzgEUbvxhfuRnKlXjkDxtK5NdT-sqR2idw/edit#heading=h.zchywovxr1yo
// Receive error string ex: "Problem while adding the payment method. Error message : This transaction has been declined."
// Format it to see if the case text exists in error string - if it does, display modal error message
export const formatPaymentErrorMessage = ({ text, type }) => {
  const declinedText = [
    "Your transaction is declined. Please contact your card issuer bank to resolve this issue or retry using a different credit card.",
    "error"
  ];
  const cvvText = ["Card code verification (CVV) is required. Please add your CVV and try again.", "warning"];
  switch (true) {
    case searchForString(text, "invalid zip/postal code"):
      return ["There is an error with your zip/postal code.", "warning"];
    case searchForString(text, "This transaction has been declined"):
      return declinedText;
    case searchForString(
      text,
      "The transaction has been declined because of an AVS mismatch. The address provided does not match the billing address of the cardholder."
    ):
      return ["The address provided does not match the billing address of the cardholder.", "warning"];
    case searchForString(text, "The credit card has expired"):
      return ["Your credit card has expired. Please use a new card to process your transaction.", "error"];
    case searchForString(text, "Customer Profile ID or Customer Payment Profile ID not found."):
      return [
        "We are missing your credit card information. Please use a different card to process your transaction.",
        "error"
      ];
    case searchForString(text, "An error occurred during processing. Please try again."):
      return [
        "Network error occurred during processing. Please try again or contact your bank to process this transaction.",
        "error"
      ];
    case searchForString(
      text,
      "Street address and postal code do not match. For American Express: Card member's name, street address and postal code do not match."
    ):
      return ["The address provided does not match the billing address of the cardholder.", "warning"];
    case searchForString(text, "Card Code is required."):
      return cvvText;
    case searchForString(text, "Bill To Address is required."):
      return ["Your billing address is missing. Please add your billing address and try again.", "warning"];
    case searchForString(
      text,
      "CVV2 check cannot be performed as no data was supplied in the authorization request."
    ):
      return cvvText;
    case searchForString(text, "The referenced transaction does not meet the criteria for issuing a credit."):
      return [
        "Your credit card bank needs additional information. Please contact your bank to process this transaction.",
        "error"
      ];
    default:
      return [text, type];
  }
};
