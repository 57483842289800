import { lazy } from "react";
import paths from "../paths";

const Login = lazy(() => import("pages/Login/index").then(module => ({ default: module.Login })));
const Register = lazy(() => import("components/Register"));
const AlibabaRegister = lazy(() => import("components/AlibabaRegister"));
const AliexpressRegister = lazy(() =>
  import("utils/redirects/AliexpressRegisterRedirect").then(module => ({
    default: module.AliexpressRegister
  }))
);
const Signup = lazy(() => import("pages/signup/index").then(module => ({ default: module.Signup })));
const ForgotPassword = lazy(() =>
  import("pages/ForgotPassword/index").then(module => ({ default: module.ForgotPassword }))
);

export const authRoutes = [
  { path: paths.auth.login, Component: Login },
  { path: paths.auth.register, Component: Register },
  { path: paths.auth.alibabaRegister, Component: AlibabaRegister },
  {
    path: paths.auth.aliexpressRegister,
    Component: AliexpressRegister
  },
  { path: paths.auth.signup, Component: Signup },
  { path: paths.auth.forgotPassword, Component: ForgotPassword }
];
