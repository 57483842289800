import colors from "./theme/colors";
import spaces from "./theme/spaces";
import fontSizes from "./theme/fontSizes";

const theme = {
  colors,
  spaces,
  fontSizes
};

export default theme;
