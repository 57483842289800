import ApiCall from "utils/apiCall";
import { setPaymentProvider } from "utils/actions/index";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "updatePaymentProvider.isFetching":
      return { ...state, isFetching: true };

    case "updatePaymentProvider.fetchSuccess":
      return { ...state, isFetching: false, ...action.response };

    case "updatePaymentProvider.fetchError":
      return { ...state, isFetching: false, ...action.response };

    default:
      return state;
  }
}

// Actions
export function updatePaymentProvider() {
  return dispatch => {
    dispatch({ type: "updatePaymentProvider.isFetching" });

    const requestPayload = {
      payment_provider: "shopify"
    };

    ApiCall.put(`/update_payment_provider`, requestPayload)
      .then(response => {
        dispatch({
          type: "updatePaymentProvider.fetchSuccess",
          response: response.json
        });

        dispatch(setPaymentProvider(response.json.payment_provider));
      })
      .catch(error => {
        dispatch({
          type: "updatePaymentProvider.fetchError",
          response: error.json
        });
      });
  };
}
