import { lazy } from "react";
import paths from "../paths";

const Search = lazy(() => import("pages/search/index"));
const Product = lazy(() => import("pages/Product"));
const Products = lazy(() => import("pages/Products").then(module => ({ default: module.Products })));
const WinningProducts = lazy(() =>
  import("pages/WinningProducts").then(module => ({ default: module.WinningProducts }))
);
const Import = lazy(() => import("pages/Import").then(module => ({ default: module.Import })));
const Reviews = lazy(() => import("pages/Reviews").then(module => ({ default: module.Reviews })));
const Checkout = lazy(() => import("pages/Checkout").then(module => ({ default: module.Checkout })));
const Payment = lazy(() => import("pages/Payment").then(module => ({ default: module.Payment })));

export const productRoutes = [
  { path: paths.product.index, Component: Search, app: true },
  { path: `${paths.product.index}/:id`, Component: Product, app: true },
  { path: paths.product.products, Component: Products, app: true, shop: true },
  {
    path: paths.product.import,
    Component: Import,
    app: true,
    shop: true
  },
  {
    path: paths.product.reviews,
    Component: Reviews,
    app: true,
    shop: true
  },
  {
    path: paths.product.winningProducts,
    Component: WinningProducts,
    app: true,
    blockedForAmazonUsers: true
  },
  { path: paths.product.checkout, Component: Checkout, app: true },
  { path: paths.product.payment, Component: Payment, app: true }
];
