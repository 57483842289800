import ApiCall from "utils/apiCall";
import { messageSuccess, messageError } from "utils/actions/index";

const initialState = {};

// Reducer
export default function action(state = initialState, action) {
  switch (action.type) {
    case "payment.getStripeInformationSuccess":
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}

// Actions
export function createStripeTokenAndSubmit(stripeData, submitData) {
  return function(dispatch) {
    const stripeOptions = { apiVersion: "2019-12-03" };
    let stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY, stripeOptions);
    return stripe
      .createToken("bank_account", stripeData)
      .then(function(result) {
        if (!result.token) {
          dispatch(messageError(result.error.message));
          return;
        }
        submitData.bank_token = result.token.id;
        dispatch(submitBankToken(submitData));
      })
      .catch(error => {
        dispatch(messageError(error));
        return;
      });
  };
}

function submitBankToken(submitData) {
  return function(dispatch) {
    ApiCall.patch("/stripe_integrations/account", {
      on_stripe: submitData
    })
      .then(() => {
        dispatch(messageSuccess("Your bank account has been submitted successfully!"));
        dispatch(getStripeInformation());
      })
      .catch(({ json }) => {
        dispatch(messageError(json.error));
      });
  };
}

export function updatePaymentInfo(submitData) {
  return function(dispatch) {
    return ApiCall.patch("/stripe_integrations/account", {
      on_stripe: submitData
    })
      .then(() => {
        dispatch(messageSuccess("Your bank account has been updated successfully!"));
        dispatch(getStripeInformation());
      })
      .catch(({ json }) => {
        dispatch(messageError(json.error));
      });
  };
}

export function getStripeInformation() {
  return function(dispatch) {
    return ApiCall.get("/stripe_integrations/account").then(({ status, json }) => {
      if (status === 200) {
        let hasAccount = false;
        if (!!json.on_stripe.country && !!json.on_stripe.legal_entity.type) {
          hasAccount = true;
        }

        const isCompleted = json.on_stripe.is_completed;

        let formValues = {
          businessName: [json.on_stripe.business_name, null],
          bankLocation: [json.on_stripe.country, null],
          accountType: [json.on_stripe.legal_entity.type, null],
          phoneNumber: [json.on_stripe.support_phone, null],
          addressOne: [json.on_stripe.legal_entity.address.line1, null],
          addressTwo: [json.on_stripe.legal_entity.address.line2, null],
          city: [json.on_stripe.legal_entity.address.city, null],
          zip: [json.on_stripe.legal_entity.address.postal_code, null],
          state: [json.on_stripe.legal_entity.address.state, null],
          businessRepFirstName: [json.on_stripe.legal_entity.first_name, null],
          businessRepLastName: [json.on_stripe.legal_entity.last_name, null],
          businessRepDOB: [returnFormattedDateOfBirth(json.on_stripe.legal_entity.dob), null],
          pageIndex: hasAccount ? 1 : 0,
          hasCompletedAccount: isCompleted
        };

        if (isCompleted) {
          const valueHidden = "********";

          formValues = Object.assign(formValues, {
            usBankInfoRoutingNumber: [valueHidden, null],
            usBankInfoAccountNumber: [valueHidden, null],
            caBankInfoAccountNumber: [valueHidden, null],
            caBankInfoTransitNumber: [valueHidden, null],
            caBankInfoInstitution: [valueHidden, null],
            IBAN: [valueHidden, null],
            personalID: [valueHidden, null],
            businessTax: [valueHidden, null]
          });
        }

        dispatch({
          type: "payment.getStripeInformationSuccess",
          data: formValues
        });
      }
    });
  };
}

function formatDOBNumber(val) {
  let pad = "00";
  return pad.substring(0, pad.length - String(val).length) + val;
}

function returnFormattedDateOfBirth(dob) {
  return dob["year"] + "-" + formatDOBNumber(dob["month"]) + "-" + formatDOBNumber(dob["day"]);
}

export function createAccount(payload, callback) {
  return function(dispatch) {
    return ApiCall.post("/stripe_integrations/account", payload)
      .then(() => {
        dispatch(getStripeInformation());
        dispatch(messageSuccess("Your bank account has been created successfully!"));
        callback();
      })
      .catch(({ json }) => {
        messageError(json);
        callback();
      });
  };
}
