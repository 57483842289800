import ApiCall from "utils/apiCall";
import { setAlertMessage } from "utils/actions";
import { openLink } from "utils/openLink";

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case "store.planCharges.isFetching":
      return { ...state, isFetching: true };

    case "store.planCharges.fetchSuccess":
      return { ...state, isFetching: false, plans: action.response };

    case "store.planCharges.fetchError":
      return { ...state, isFetching: false, plans: action.response };

    default:
      return state;
  }
}

// Actions

export function createPlanCharge(requestPayload) {
  return (dispatch, getState) => {
    dispatch({ type: "store.planCharges.isFetching" });

    const { storeId } = getState().settings;

    ApiCall.post(`/stores/${storeId}/plan_charges`, requestPayload)
      .then(response => {
        openLink(response.json.confirmation_url, "_self");
      })
      .catch(error => {
        dispatch({ type: "store.planCharges.fetchError", error });
        dispatch(setAlertMessage("Something went wrong. Please, contact our support.", "error"));
      });
  };
}
