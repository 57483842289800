import { authRoutes } from "./auth";
import { productRoutes } from "./products";
import { promotionRoutes } from "./promotions";
import { appRoutes } from "./app";
import { settingsRoutes } from "./settings";
import { legalRoutes } from "./legal";
import { supplierRoutes } from "./suppliers";
import { accountRoutes } from "./account";
import { nonAuthRoutes } from "./nonAuth";

export default [
  ...authRoutes,
  ...productRoutes,
  ...promotionRoutes,
  ...appRoutes,
  ...settingsRoutes,
  ...legalRoutes,
  ...supplierRoutes,
  ...accountRoutes,
  ...nonAuthRoutes
];
