import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Routes
import paths from "routes/paths";

// Services
import { useSettings } from "utils/api/settings/queries";

export default RequireNonAmazonAccountChildComponent => {
  const Component = ({ props }) => {
    const { data: accountData, isLoading: accountDataIsLoading } = useSettings();
    const [isAmazonUser, setIsAmazonUser] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      if (accountData) {
        const { store } = accountData;
        const { integrated_store_name: integratedStoreName = "" } = store;
        if (integratedStoreName === "amazon") setIsAmazonUser(true);
      }
    }, [accountData]);

    useEffect(() => {
      if (isAmazonUser) navigate(paths.app.search);
    }, [isAmazonUser]);

    if (accountDataIsLoading) return false;

    return <RequireNonAmazonAccountChildComponent data-testid="require-non-amazon-account" {...props} />;
  };

  return Component;
};
