import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Routes
import paths from "routes/paths";

// Services
import { useSettings } from "utils/api/settings/queries";

export default RequireNotBlockedRouteChange => {
  const Component = ({ props }) => {
    const { data: accountData } = useSettings();
    const [userIsCurrentlyOnOnboarding, setUserIsCurrentlyOnOnboarding] = useState(false);
    const navigate = useNavigate();
    const currentUrl = window.location.pathname;
    const isBlockedPath =
      currentUrl === paths.app.orders ||
      currentUrl === paths.product.products ||
      currentUrl === paths.app.jubilee ||
      currentUrl === paths.product.winningProducts ||
      currentUrl === paths.app.notifications ||
      currentUrl === paths.app.chat ||
      currentUrl === paths.account.connect ||
      currentUrl === paths.product.import;

    useEffect(() => {
      if (accountData) {
        const { store = {} } = accountData;
        const { show_personalized_onboarding: showPersonalizedOnboarding = "" } = store;
        if (showPersonalizedOnboarding) setUserIsCurrentlyOnOnboarding(true);
      }
    }, [accountData]);

    useEffect(() => {
      // If user is in onboarding and they are not on Search or Payment, move them to Search
      if (userIsCurrentlyOnOnboarding && isBlockedPath) navigate(paths.app.search);
    }, [userIsCurrentlyOnOnboarding]);

    return <RequireNotBlockedRouteChange data-testid="require-not-blocked" {...props} />;
  };

  return Component;
};
