import ApiCall from "utils/apiCall";
import Cookies from "js-cookie";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "utm.isFetching":
      return { ...state, isFetching: true };

    case "utm.fetchSuccess":
      return { ...state, isFetching: false };

    case "utm.fetchError":
      return { ...state, isFetching: false };

    default:
      return state;
  }
}

// Actions
export function sendUtm() {
  return dispatch => {
    const spocketUtm = Cookies.get("spocket_utm");
    if (spocketUtm) {
      dispatch({ type: "utm.isFetching" });
      ApiCall.post(`/stores/utm`, formatUtm(spocketUtm))
        .then(() => dispatch({ type: "utm.fetchSuccess" }))
        .catch(error => {
          dispatch({ type: "utm.fetchError", error });
        });
    }
  };
}

// Utils
const formatUtm = utms => {
  const arrayOfUtms = utms
    .split("?")[1]
    .split("&")
    .map(utm => {
      const splitedUtm = utm.split("=");
      const key = splitedUtm[0];
      const value = splitedUtm[1];
      return { [key]: value };
    });

  return Object.assign({}, ...arrayOfUtms);
};
