import { lazy } from "react";
import paths from "../paths";

const DataProcessingAddendum = lazy(() => import("components/DataProcessingAddendum"));
const Privacy = lazy(() => import("components/Privacy"));
const Terms = lazy(() => import("components/Terms"));

export const legalRoutes = [
  { path: paths.legal.dataProcessingAddendum, Component: DataProcessingAddendum, app: true },
  { path: paths.legal.privacy, Component: Privacy, app: true },
  { path: paths.legal.terms, Component: Terms, app: true }
];
