import React, { lazy } from "react";
import paths from "../paths";

const Suppliers = lazy(() => import("pages/Suppliers"));

export const supplierRoutes = [
  { path: paths.suppliers, Component: () => <Suppliers hasSupplier={false} />, app: true },
  {
    path: `${paths.suppliers}/:supplierAlias`,
    Component: () => <Suppliers hasSupplier={true} />,
    app: true
  }
];
