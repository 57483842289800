// Libs
import ApiCall from "utils/apiCall";
import * as Sentry from "@sentry/react";

// Actions
import { setAlertMessage, verify } from "utils/actions";
import { closeModal, clearSelectedProduct } from "./ui";
import { updatePaywallView } from "utils/actions/tracker";

// Module
import { createPlanCharge } from "utils/module/store/planCharges";

// Utils
import { postUpgradeTrackingEvents } from "utils/trackingEvents/upgradeEvents";
import { formatErrorMessage } from "utils/formatErrorMessage";

// Services
import { createSubscription } from "utils/api/payments/requests";
import { isCreditCardPaymentMethod } from "types/payments";

import paths from "routes/paths";

export const UPGRADE_URL = "UPGRADE_URL";
export const UPGRADE_ERROR = "UPGRADE_ERROR";
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const IS_UPGRADING = "IS_UPGRADING";
export const MODAL_UPGRADE_TYPE = "MODAL_UPGRADE_TYPE";

export function handleUpgradeRequest({
  planID,
  planName,
  tracker,
  plan,
  listing,
  paymentMethod,
  navigate,
  couponUid,
  createStoreAfterUpgrade = false,
  hCaptchaToken,
  usePromotionalPlan = false,
  userHasUpgradedFromModal = false
}) {
  return function(dispatch, getState) {
    // State from Redux store
    const { paymentProvider, storeId } = getState().settings;
    const { eventId } = getState().eventTracker;
    const userUpgradedToAnnualPlan = plan.annual;

    // This function will be triggered after the upgrade.
    const showUpgradeSuccessModal = ({ subscriptionId, subscriptionConfirmationUrl }) => {
      postUpgradeTrackingEvents({ plan, subscriptionId, listing });

      localStorage.removeItem("landing_page_signup");

      dispatch(modalClose());
      dispatch(isUpgrading(false));

      if (plan.alias === "Promotional") {
        plan.alias = "Pro";
      }

      if (
        (paymentProvider === "shopify_payment" && !userUpgradedToAnnualPlan) ||
        paymentProvider === "wix_payment"
      ) {
        return;
      } else {
        const hasSubscriptionConfirmationUrl =
          subscriptionConfirmationUrl !== undefined &&
          subscriptionConfirmationUrl !== null &&
          subscriptionConfirmationUrl &&
          subscriptionConfirmationUrl.length > 0;

        // If user has upgraded through promotion modal, they won't see anything more
        const shouldDisplayAnnualPromotion = userHasUpgradedFromModal ? null : "ANNUAL_PROMOTION_MODAL";

        const modalToBeDisplayed = userUpgradedToAnnualPlan
          ? "UPGRADE_SUCCESS"
          : shouldDisplayAnnualPromotion;

        if (userHasUpgradedFromModal) {
          dispatch(closeModal());
        }

        // Post Upgrade redirect
        let paramsHash = {
          plan_name: plan.name,
          annual: plan.annual,
          has_subscription_confirmation_url: hasSubscriptionConfirmationUrl
        };
        if (modalToBeDisplayed !== null) {
          paramsHash = {
            ...paramsHash,
            upgrade_modal: modalToBeDisplayed
          };
        }
        const queryString = Object.keys(paramsHash)
          .map(key => key + "=" + paramsHash[key])
          .join("&");
        navigate(`${paths.settings.chargebeePaymentSuccess}?${queryString}`); // TODO: make hook or sth
      }
    };

    // Building the requestPayload to be send to createPlanCharge() and createSubscription() actions
    const requestPayload = {
      plan_id: planID,
      coupon_uid: couponUid,
      upgrade_with_store: createStoreAfterUpgrade,
      use_promotional_plan: usePromotionalPlan,
      tracking_plan_upgrade: {
        ...tracker
      },
      "h-captcha-response": hCaptchaToken
    };

    if (eventId) {
      dispatch(
        updatePaywallView({
          eventId,
          upgradeStep: "process_upgrade"
        })
      );
    }

    if (
      (paymentProvider === "shopify_payment" && !userUpgradedToAnnualPlan) ||
      paymentProvider === "wix_payment"
    ) {
      dispatch(createPlanCharge(requestPayload));
    } else {
      return createSubscription({ storeId, payload: requestPayload })
        .then(res => {
          const { data } = res || {};
          const { id: subscriptionId, confirmation_url: subscriptionConfirmationUrl } = data;
          dispatch(verify(navigate));
          showUpgradeSuccessModal({ subscriptionId, subscriptionConfirmationUrl });
        })
        .catch(error => {
          if (isCreditCardPaymentMethod(paymentProvider)) {
            const errMessage = formatErrorMessage(error);
            Sentry.captureException(`Chargebee Subscription Error - ${errMessage}`);
          }
          return Promise.reject(error);
        });
    }
  };
}
export function handleUpgradeRequestSwitchStore({
  planID,
  tracker,
  plan,
  navigate,
  couponUid,
  createStoreAfterUpgrade = false,
  hCaptchaToken,
  storeId
}) {
  return function(dispatch, getState) {
    // State from Redux store
    const userUpgradedToAnnualPlan = plan.annual;

    // This function will be triggered after the upgrade.
    const showUpgradeSuccessModal = ({ subscriptionId, subscriptionConfirmationUrl }) => {
      postUpgradeTrackingEvents({
        plan,
        subscriptionId,
        undefined
      });

      localStorage.removeItem("landing_page_signup");

      dispatch(modalClose());
      dispatch(isUpgrading(false));

      if (plan.alias === "Promotional") {
        plan.alias = "Pro";
      }

      // Post Upgrade redirect
      const hasSubscriptionConfirmationUrl =
        subscriptionConfirmationUrl !== undefined &&
        subscriptionConfirmationUrl !== null &&
        subscriptionConfirmationUrl &&
        subscriptionConfirmationUrl.length > 0;
      const modalToBeDisplayed = userUpgradedToAnnualPlan ? "UPGRADE_SUCCESS" : "ANNUAL_PROMOTION_MODAL";
      const paramsHash = {
        upgrade_modal: modalToBeDisplayed,
        plan_name: plan.name,
        annual: plan.annual,
        has_subscription_confirmation_url: hasSubscriptionConfirmationUrl
      };
      const queryString = Object.keys(paramsHash)
        .map(key => key + "=" + paramsHash[key])
        .join("&");
      navigate(`${paths.settings.chargebeePaymentSuccess}?${queryString}`); // TODO: make hook or sth
    };

    // Building the requestPayload to be send to createPlanCharge() and createSubscription() actions
    const requestPayload = {
      plan_id: planID,
      coupon_uid: couponUid,
      upgrade_with_store: createStoreAfterUpgrade,
      tracking_plan_upgrade: {
        ...tracker
      },
      "h-captcha-response": hCaptchaToken
    };

    return createSubscription({ storeId, payload: requestPayload })
      .then(res => {
        const { data } = res || {};
        const { id: subscriptionId, confirmation_url: subscriptionConfirmationUrl } = data;
        return showUpgradeSuccessModal({ subscriptionId, subscriptionConfirmationUrl });
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

// TODO Maybe we can remove this code since isn't used
export function updatePlanChargeReason(upgradeReasons, upgradeComment, navigate) {
  return function(dispatch, getState) {
    ApiCall.post(`/stores/update_plan_charge_reason`, {
      upgrade_reasons: upgradeReasons,
      upgrade_comment: upgradeComment
    })
      .then(async () => {
        dispatch(closeModal());
        const selectedProduct = getState().ui.selectedProduct;

        const isPathnameSearchPage = window.location.pathname === paths.app.search;
        const listingIDToBeAddedToImportList = localStorage.getItem("listing_id_to_be_added_to_import_list");

        if (listingIDToBeAddedToImportList) {
          localStorage.removeItem("listing_id_to_be_added_to_import_list");

          if (!isPathnameSearchPage) {
            navigate(paths.app.search); // TODO: make hook or sth
          }
          if (selectedProduct) {
            dispatch(clearSelectedProduct());
            navigate(`${paths.product.index}/${selectedProduct.productId}`); // TODO: make hook or sth
          }
        } else {
          !isPathnameSearchPage && navigate(paths.app.search); // TODO: make hook or sth
          if (selectedProduct) {
            dispatch(clearSelectedProduct());
            navigate(`${paths.product.index}/${selectedProduct.productId}`); // TODO: make hook or sth
          }
        }
      })
      .catch(error => {
        dispatch(setAlertMessage(error.json.errors, "error"));
      });
  };
}

export function isUpgrading(isUpgrading) {
  return {
    type: IS_UPGRADING,
    payload: {
      isUpgrading: isUpgrading
    }
  };
}

export function upgradeUrl(json) {
  return {
    type: UPGRADE_URL,
    payload: {
      url: json.confirmation_url
    }
  };
}

export function upgradeError(json) {
  return {
    type: UPGRADE_ERROR,
    payload: {
      error: json.message
    }
  };
}

export function openUpgradeModal(type) {
  return function(dispatch) {
    dispatch(modalOpen());
    dispatch(modalUpgradeType(type));
  };
}

export function modalUpgradeType(type) {
  return {
    type: MODAL_UPGRADE_TYPE,
    payload: {
      modalUpgradeType: type
    }
  };
}

export function modalOpen() {
  return {
    type: MODAL_OPEN,
    payload: {
      modalOpen: true
    }
  };
}

export function closeUpgradeModal() {
  return function(dispatch) {
    dispatch(modalClose());
  };
}

export function modalClose() {
  return {
    type: MODAL_CLOSE,
    payload: {
      modalOpen: false
    }
  };
}
