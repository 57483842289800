// as per https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Checks if email is valid based on regex
export const isEmailValid = (email = "") => {
  return EMAIL_REGEX.test(email);
};

// Checks if given email is a valid @spocket.co address
export const isSpocketEmail = (email = "") => {
  if (!isEmailValid(email)) return false;
  const domain = email.split("@")[1];
  return (
    domain === "spocket.co" || email === "tom.hansen.cc@gmail.com" || email === "productleader08+01@gmail.com"
  );
};

// Checks if the logged user have a spocket email
export const isSpocketUser = () => {
  return isSpocketEmail(localStorage.getItem("user_email"));
};
