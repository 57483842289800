import { store } from "utils/store/configureStore";
import { signOutUser } from "utils/actions/index";
import * as Sentry from "@sentry/react";

import paths from "routes/paths";

/**
  Service to handle ajax requests to any api
  This service is not used directly, this is

  TODO:
  - Create Put, Post and Delete
 */

export default class RequestMethodsBase {
  /**
   * Make a get request to our api
   * @param  {String} url    path to resource without host
   * @param  {Hash} params   Query params
   * @return {Promise}
   */
  static get(url, params, type) {
    return fetch(this._mountUrlWithParams(url, params), {
      headers: this._buildHeaders(type)
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse)
      .catch(error => {
        if (
          window.location.pathname !== paths.app.maintenance &&
          error &&
          !error.status &&
          !error.hasOwnProperty("TypeError")
        ) {
          // window.location.replace(paths.app.maintenance);
          Sentry.captureException(`GET - requestMethodsBase - ${JSON.stringify(error)}`);
          Sentry.addBreadcrumb({ category: "error", message: error, level: "error" });
        } else {
          throw error;
        }
      });
  }

  /**
   * Post request with stringified body.
   * @param  {String} url
   * @param  {Hash} data
   * @return {Promise}
   */
  static post(url, data) {
    return fetch(this._mountUrlWithParams(url, {}), {
      method: "POST",
      headers: this._buildHeaders(),
      body: JSON.stringify(data)
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse);
  }

  static patch(url, data) {
    return fetch(this._mountUrlWithParams(url, {}), {
      method: "PATCH",
      headers: this._buildHeaders(),
      body: JSON.stringify(data)
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse);
  }

  static put(url, data) {
    return fetch(this._mountUrlWithParams(url, {}), {
      method: "PUT",
      headers: this._buildHeaders(),
      body: JSON.stringify(data)
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse);
  }

  /**
   * Make a delete request to our api
   * @param  {String} url    path to resource without host
   * @param  {Hash} params   Query params
   * @return {Promise}
   */
  static delete(url, params) {
    return fetch(this._mountUrlWithParams(url, params), {
      headers: this._buildHeaders(),
      method: "DELETE"
    })
      .then(this._rejectBadResponse)
      .then(this._jsonResponse);
  }

  // private

  /**
   * Returns a rejected promise with a status and a message if the response
   * is invalid.
   * @param  {Response}
   * @return {Response} or new rejected promise
   */
  static _rejectBadResponse(response) {
    // Note: we might want to check for other statuses or conditions here
    if (response.status >= 500) {
      return Promise.reject({
        status: response.status,
        message: response.statusText,
        json: { message: response.body }
      });
    } else {
      return response;
    }
  }

  /**
   * Converts the response to json
   * @param  {Response}
   * @return {Object} status, json
   */
  static _jsonResponse(response) {
    if (response.status === 204) {
      return Promise.resolve({
        status: response.status,
        json: {}
      });
    }

    return response.json().then(json => {
      const body = { status: response.status, json };
      if (response.ok) {
        return body;
      } else {
        return Promise.reject(body);
      }
    });
  }

  /**
   * Check if the user is unauthorized and log them out if they are.
   * @param  {Response} response
   * @return {Response}
   */
  static _checkAuth(response) {
    if (response.status === 401) {
      store.dispatch(signOutUser());
      return Promise.reject({
        status: response.status,
        json: { message: "Your session has expired. Please log in." }
      });
    } else {
      return response;
    }
  }

  static _queryParams(source) {
    let array = [];

    for (let key in source) {
      array.push(encodeURIComponent(key) + "=" + encodeURIComponent(source[key]));
    }

    return array.join("&");
  }

  /**
   * Simple mount url request with query params
   * @param  {String} url
   * @param  {Hash} params
   * @return {String}
   */
  static _mountUrlWithParams(url, params = {}) {
    if (params && Object.keys(params).length) {
      const queryParamsSerialized = this._queryParams(params);
      return `${this.API_URL}${url}?${encodeURI(queryParamsSerialized)}`;
    } else {
      return `${this.API_URL}${url}`;
    }
  }

  /**
   * Build headers for fetch request with autorizatoin token
   * @return {Headers}
   */
  static _buildHeaders(type = "json") {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("content-type", `application/${type}`);
    headers.append("Authorization", `Bearer ${this._token()}`);
    return headers;
  }

  /**
   * Token for api JWT autorization
   * @return {String}
   */
  static _token() {
    return localStorage.getItem("auth_token");
  }
}
