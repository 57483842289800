const fontSizes = {
  fontSize70: "28px",
  fontSize60: "22px",
  fontSize50: "18px",
  fontSize40: "16px",
  fontSize30: "14px",
  fontSize20: "12px",
  fontSize10: "10px"
};

export default fontSizes;
