import { useQuery } from "@tanstack/react-query";
import { getPricingRules, getSettings, getSquareLocations } from "./requests";
import { FETCH_PRICING_RULES_QUERY, FETCH_SETTINGS_QUERY, FETCH_SQUARE_LOCATIONS_QUERY } from "./types";

export const useSettings = () => {
  return useQuery([FETCH_SETTINGS_QUERY], getSettings);
};

export const useSquareLocation = id => {
  return useQuery([FETCH_SQUARE_LOCATIONS_QUERY, () => getSquareLocations(id)]);
};

export const usePricingRule = () => {
  return useQuery([FETCH_PRICING_RULES_QUERY], getPricingRules);
};
