import ApiCall from "utils/apiCall";
import { setAlertMessage } from "utils/actions";
import { openLink } from "utils/openLink";

const initialState = {
  isFetching: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.paypal.paymentSubscribe.isFetching":
      return { ...state, isFetching: true };

    case "store.paypal.paymentSubscribe.fetchSuccess":
      return { ...state, isFetching: false, plans: action.response };

    case "store.paypal.paymentSubscribe.fetchError":
      return { ...state, isFetching: false, plans: action.response };

    default:
      return state;
  }
}

// Actions

export function createPayPalCharge({ planId, upgradeWithStore = false }) {
  return dispatch => {
    dispatch({ type: "store.paypal.paymentSubscribe.isFetching" });
    ApiCall.get(`/stores/paypal/payment_subscribe/${planId}?upgrade_with_store=${upgradeWithStore}`)
      .then(({ json }) => {
        openLink(json.paypal_checkout_url, "_self");
      })
      .catch(error => {
        dispatch({ type: "store.paypal.paymentSubscribe.fetchError", error });
        dispatch(setAlertMessage("Something went wrong. Please, contact our support.", "error"));
      });
  };
}
