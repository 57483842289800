import { lazy } from "react";
import paths from "../paths";

const Settings = lazy(() => import("components/Settings").then(module => ({ default: module.Settings })));
const ChargebeePaymentSuccess = lazy(() => import("pages/ChargebeePaymentSuccess"));
const Paypal = lazy(() => import("pages/PayPal/index"));

export const settingsRoutes = [
  { path: paths.settings.plans, Component: Settings, app: true },
  { path: paths.settings.account, Component: Settings, app: true },
  { path: paths.settings.membership, Component: Settings, app: true },
  { path: paths.settings.store, Component: Settings, app: true },
  { path: paths.settings.pricing, Component: Settings, app: true },
  { path: paths.settings.chargebeePaymentSuccess, Component: ChargebeePaymentSuccess, app: true },
  // paypal
  { path: paths.settings.paypalPaymentSuccess, Component: Paypal, app: true },
  { path: paths.settings.paypalPaymentCancel, Component: Paypal, app: true }
];
