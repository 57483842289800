import ApiCall from "utils/apiCall";

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case "analytics.topSellingProducts.isFetching":
      return { ...state, isFetching: true };

    case "analytics.topSellingProducts.fetchSuccess":
      return { ...state, isFetching: false, suppliers: action.response };

    case "analytics.topSellingProducts.fetchError":
      return { ...state, isFetching: false, suppliers: action.response };

    default:
      return state;
  }
}

// Actions

export function getTopProducts() {
  return dispatch => {
    dispatch({ type: "analytics.topSellingProducts.isFetching" });
    ApiCall.get("/top_selling_products")
      .then(response => {
        dispatch({
          type: "analytics.topSellingProducts.fetchSuccess",
          response: response.json
        });
      })
      .catch(error => {
        dispatch({ type: "analytics.topSellingProducts.fetchError", error });
      });
  };
}
