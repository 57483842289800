import ApiCall from "utils/apiCall";

const initialState = {
  products: []
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "todaysSales.isFetching":
      return { ...state, isFetching: true };

    case "todaysSales.fetchSuccess":
      return { ...state, isFetching: false, products: action.response };
    case "todaysSales.successUrl":
      return {
        ...state,
        isFetching: false,
        successUrl: [...state.successUrl, action.response]
      };

    case "todaysSales.fetchError":
      return { ...state, isFetching: false };

    default:
      return state;
  }
}

// Actions
export function getTodaysSales() {
  return async dispatch => {
    dispatch({ type: "todaysSales.isFetching" });
    try {
      const response = await ApiCall.get("/todays_sales");

      dispatch({
        type: "todaysSales.fetchSuccess",
        response: response.json
      });
    } catch (error) {
      dispatch({ type: "todaysSales.fetchError", error });
    }
  };
}
