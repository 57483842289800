export const CHARGEBEE_PAYMENT = "chargebee_payment";
export const STRIPE_PAYMENT = "stripe_payment";
// We check the endpoint /account { store: { stripe_subscription_accout }} to check which account of stripe we should use
export const STRIPE_SPOCKET_SUBSCRIPTION_ACCOUNT = "spocket_subscription";
export const STRIPE_SPOCKET_USA_SUBSCRIPTION_ACCOUNT = "spocket_usa_subscription";
export const STRIPE_DROPGENIUS_SUBSCRIPTION_ACCOUNT = "dropgenius_subscription";

export const isCreditCardPaymentMethod = payment =>
  payment === CHARGEBEE_PAYMENT || payment === STRIPE_PAYMENT;

// Stripe payment authorization flow
export const STRIPE_PAYMENT_AUTHORIZATION_SUCCESS = "active";
export const STRIPE_PAYMENT_AUTHORIZATION_PENDING = "pending";
