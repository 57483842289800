import ApiCall from "utils/apiCall";

const initialState = {
  isFetching: false,
  allOffers: []
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "store.offers.isFetching":
      return { ...state, isFetching: true };

    case "store.offers.fetchSuccess":
      return { ...state, isFetching: false, store: action.response };

    case "store.offers.allOffers.fetchSuccess":
      return { ...state, isFetching: false, allOffers: action.response };

    case "store.offers.fetchError":
      return { ...state, isFetching: false };

    default:
      return state;
  }
}

// Actions
export function getAllOffers() {
  return async dispatch => {
    dispatch({ type: "store.offers.isFetching" });
    try {
      const response = await ApiCall.get(`/stores/offers`);
      dispatch({
        type: "store.offers.allOffers.fetchSuccess",
        response: response.json
      });
    } catch (error) {
      dispatch({ type: "store.offers.fetchError" });
    }
  };
}
